<template>
  <div>
    <!-- Loading -->
    <div
      v-if="isLoading"
      class="d-flex flex-column align-items-center vertical-center"
    >
      <b-spinner variant="primary" />
      <span class="small-gap-top">Memuat Soal</span>
    </div>

    <!-- Quiz Section -->
    <div v-if="!isLoading">
      <!-- Quiz Submission Card Header -->
      <b-card
        bg-variant="transparent"
        class="material-card border-light-secondary mb-1"
      >
        <div class="d-flex">
          <div class="mr-1">
            <b-avatar
              rounded="lg"
              :variant="taskType(taskSession.type).variant"
            >
              <i
                class="card-icon"
                :class="taskType(taskSession.type).icon"
              />
            </b-avatar>
          </div>
          <div class="d-flex flex-column">
            <div class="d-flex justify-content-between">
              <div class="d-flex flex-column ">
                <span class="text-muted ">
                  {{ taskType(taskSession.type).title }}
                </span>
                <h6 class="font-weight-bolder small-gap-bottom">
                  {{ taskSession.title }}
                </h6>
              </div>
            </div>
          </div>
        </div>
      </b-card>

      <!-- Multiple Choice -->
      <b-card
        bg-variant="transparent"
        class="material-card border-light-secondary p-sm-1"
      >
        <b-row>
          <b-col md="8">

            <!-- Exam Content -->
            <div>
              <h6 class="font-weight-bolder small-gap-bottom">
                Pertanyaan
              </h6>

              <!-- Exam Question -->
              <div class="d-flex mb-1">
                <b-avatar
                  class="small-gap-right"
                  variant="primary"
                >
                  {{ questions[questionIndex].number }}
                </b-avatar>
                <p
                  class="font-weight-normal"
                  v-html="questions[questionIndex].question"
                />
              </div>

              <h6 class="font-weight-bolder small-gap-bottom">
                Jawaban
              </h6>

              <!-- Exam Answer Essay -->
              <div
                class="mt-1"
              >
                <b-form-group>
                  <b-input-group class="small-gap-bottom d-block">
                    <quill-editor
                      ref="quillEditor"
                      v-model="answer"
                      :options="editorOptions"
                    />
                  </b-input-group>
                </b-form-group>
              </div>
            </div>
          </b-col>
          <b-col md="4">
            <!-- Progress Info -->
            <div class="progress-quiz">
              <div class="d-flex justify-content-between">
                <h6 class="font-weight-bolder small-gap-bottom">
                  Progress
                </h6>
              </div>
              <b-progress
                class="small-gap-top small-gap-bottom"
                :value="quizProgress"
                max="100"
              />
            </div>
            <div
              class="d-flex justify-content-between align-items-center small-gap-bottom"
            >
              <h6 class="font-weight-bolder mb-0">
                Nomor Soal
              </h6>

              <!-- Quiz Start Date -->
              <!-- <b-badge variant="light-secondary">
              Senin 10 Oktober 2022, 10:00 WIB
            </b-badge> -->
            </div>

            <!-- Exam Navigation -->
            <div>
              <b-row>
                <b-col>
                  <div class="d-flex flex-wrap">
                    <b-button
                      v-for="(nav, index) in questionNavigations"
                      :key="index"
                      class="round-button text-center"
                      :variant="getQuizNavigationVariant(nav)"
                      size="sm"
                      pill
                      @click="navigateToQuiz(index)"
                    >
                      {{ index + 1 }}
                    </b-button>
                  </div>
                </b-col>
              </b-row>
              <b-row class="mt-1">
                <b-col class="d-flex justify-content-center flex-wrap">
                  <b-badge
                    class="px-1 small-gap-right small-gap-bottom"
                    variant="warning"
                  >
                    Ditandai
                  </b-badge>
                  <b-badge
                    class="px-1 small-gap-right small-gap-bottom"
                    variant="secondary"
                  >
                    Dijawab
                  </b-badge>
                  <b-badge
                    class="px-1 small-gap-right small-gap-bottom outline-primary"
                    variant="light-danger"
                  >
                    Belum Dijawab
                  </b-badge>
                  <b-badge
                    class="px-1 small-gap-bottom"
                    variant="primary"
                  >
                    Sekarang
                  </b-badge>
                </b-col>
              </b-row>
            </div>
          </b-col>

          <!-- Action Button -->
          <b-col
            md="12"
            class="border-top pt-1 mt-1"
          >
            <b-button
              v-show="!questionIndex == 0"
              variant="outline-primary"
              @click="navigateToQuiz(-1, true)"
            >
              <i class="align-middle ti ti-arrow-left" />
            </b-button>
            <b-button
              :variant="iscurrentQuestionMarked ? 'warning' : 'outline-warning'"
              class="ml-1"
              @click="markQuiz"
            >
              <i class="align-middle ti ti-star" />
              <span class="align-middle ml-1  d-none d-sm-inline">
                {{ iscurrentQuestionMarked ? 'Ditandai' : 'Tandai' }}
              </span>
            </b-button>
            <b-button
              v-show="questionIndex !== questions.length - 1"
              variant="primary"
              class="ml-1"
              @click="navigateToQuiz(1, true)"
            >
              <span class="align-middle d-none d-sm-inline">Selanjutnya</span>
              <i class="align-middle ti ti-arrow-right" />
            </b-button>
            <b-button
              v-show="questionIndex === questions.length - 1"
              variant="primary"
              class="ml-1"
              @click="submitForm"
            >
              <i class="align-middle ti ti-device-floppy mr-1" />
              <span class="align-middle ">Simpan</span>
            </b-button>
          </b-col>
        </b-row>
      </b-card>
    </div>
  </div>
</template>

<script>
import { checkAuthorizeRole } from '@/auth/utils'
import { avatarText, taskType } from '@core/utils/filter'

import {
  BBadge,
  BRow,
  BCol,
  BCard,
  BButton,
  BInputGroup,
  BAvatar,
  BProgress,
  BFormGroup,
  BSpinner,
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

// eslint-disable-next-line
import 'quill/dist/quill.core.css'
// eslint-disable-next-line
import 'quill/dist/quill.snow.css'
// eslint-disable-next-line
import 'quill/dist/quill.bubble.css'

import { quillEditor } from 'vue-quill-editor'
import ImageUploader from 'quill-image-uploader'
import { reqGetTaskEssayQuestions, reqSaveTaskEssayAnswer, reqFinsihTaskEssay } from '@/api/user/task'
import { reqUploadFile } from '@/api/user/file'
import Quill from 'quill'
import axios from 'axios'

Quill.register('modules/image-uploader', ImageUploader)

export function imageHandler() {
  const { quill } = this

  const input = document.createElement('input')

  input.setAttribute('type', 'file')
  input.setAttribute('accept', 'image/*')
  input.click()

  input.onchange = async () => {
    const file = input.files[0]
    const formData = new FormData()

    formData.append('file', file)

    // Save current cursor state
    const range = quill.getSelection(true)

    // Insert temporary loading placeholder image
    quill.insertEmbed(range.index, 'image', 'https://cdn.dribbble.com/users/1341307/screenshots/5377324/morph_dribbble.gif')

    // Move cursor to right side of image (easier to continue typing)
    quill.setSelection(range.index + 1)

    // THIS NEEDS TO BE HOOKED UP TO AN API
    // RESPONSE DATA WILL THEN BE USED TO EMBED THE IMAGE
    // const res = await apiPostNewsImage(formData);

    // Remove placeholder image
    quill.deleteText(range.index, 1)

    try {
      const res = await reqUploadFile(formData)
      // Insert uploaded image
      quill.insertEmbed(range.index, 'image', res.data.url)
    } catch (err) {
      console.error('error', err)
    }
  }
}

export default {
  components: {
    BBadge,
    BRow,
    BCol,
    BCard,
    BButton,
    BInputGroup,
    BAvatar,
    BProgress,
    BFormGroup,
    BSpinner,

    quillEditor,
  },
  props: {
    contentData: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      // State
      isLoading: true,
      questions: [],
      taskSession: {},

      // Default data
      classroomId: this.$router.currentRoute.params.id,
      contentId: this.$router.currentRoute.params.contentId,
      taskId: this.$router.currentRoute.params.taskId,
      contentType: 'essay',

      // Exam Data
      questionIndex: 0,
      quizLastViewed: 0,
      countIsAnswered: 0,
      questionNavigations: [],
      quizzes: [],
      answer: null,

      // Quiz Data and Session
      quizOverview: {},
      taskSession: {},

      // Timer
      timeDurationOnSecond: 0,
      timePassed: 0,
      timerInterval: null,
      timer: {
        hour: 0,
        minute: 0,
        second: 0,
      },

    }
  },
  // Breadcrumb data
  beforeRouteEnter(to, from, next) {
    const { role, breadcrumb } = to.meta

    to.meta.breadcrumb[breadcrumb.length - 2].params = {
      id: to.params.id,
      contentId: to.params.contentId,
    }
    to.meta.breadcrumb[breadcrumb.length - 3].params = { id: to.params.id }
    next()
  },
  computed: {
    currentQuestion() {
      return this.questions[this.questionIndex]
    },
    iscurrentQuestionMarked() {
      return this.questionNavigations[this.questionIndex].isMarked
    },
    quizProgress() {
      return (this.countIsAnswered / this.questions.length) * 100
    },

    // Timer
    timeLeft() {
      return this.timeDurationOnSecond - this.timePassed
    },
    countdown() {
      const hour = Math.floor(this.timeLeft / 3600)
      const minute = Math.floor((this.timeLeft % 3600) / 60)
      const second = this.timeLeft % 60

      return `${hour < 10 ? `0${hour}` : hour}:${
        minute < 10 ? `0${minute}` : minute
      }:${second < 10 ? `0${second}` : second}`
    },
    timeFraction() {
      const rawTimeFraction = this.timeLeft / this.timeDurationOnSecond
      return (
        rawTimeFraction
        - (1 / this.timeDurationOnSecond) * (1 - rawTimeFraction)
      )
    },
    editorOptions() {
      return {
        modules: {
          toolbar: {
            container: [
              ['bold', 'italic', 'underline', 'strike'],
              ['blockquote', 'code-block'],
              [{ list: 'ordered' }, { list: 'bullet' }],
              [{ indent: '-1' }, { indent: '+1' }],
              ['image'], // Include the "image" button in the toolbar
              ['clean'],
            ],
            handlers: {
              image: imageHandler,
            },
          },
        },
      }
    },
  },

  watch: {
    timeLeft(newValue) {
      if (newValue === 0) {
        this.onTimesUp()
      }
    },
  },

  async mounted() {
    this.taskSession = JSON.parse(localStorage.getItem('taskSession'))
    this.isLoading = true
    await reqGetTaskEssayQuestions(this.$route.params.taskId).then(response => {
      this.questions = response.data.data
      this.generateNavigationState()
      this.answer = this.questions[this.questionIndex].answer_text
      this.countAnswered()
      this.isLoading = false
    }).catch(error => {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Gagal',
          icon: 'XIcon',
          text: error.response.data.message,
          variant: 'danger',
        },
      })
      this.$router.go(-1)
    })
  },

  methods: {
    // Filter Method
    checkAuthorizeRole,
    avatarText,
    taskType,
    showToast(variant, icon, title, message) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title,
          icon,
          text: message,
          variant,
        },
      })
    },

    saveAnswer(answer) {
      this.answer = answer
      const { id } = this.questions[this.questionIndex]
      const { number } = this.questions[this.questionIndex]
      this.questions[this.questionIndex].answer_text = answer
      if (answer) {
        reqSaveTaskEssayAnswer(id, { text: answer })
          .catch(() => {
            alert(`gagal menyimpan jawaban nomor: ${number}`)
          })
      }
    },

    // Quiz Navigations
    generateNavigationState() {
      const navigations = []
      for (let i = 0; i < this.questions.length; i++) {
        navigations.push({
          question_id: this.questions[i].id,
          number: i + 1,
          isAnswered: !!this.questions[i].answer_text,
          isMarked: false,
        })
      }
      this.questionNavigations = navigations
      this.countIsAnswered = this.countAnswered()
    },
    getQuizNavigationVariant(nav) {
      if (nav.number <= this.questions.length) {
        // if current question

        if (
          nav.number === this.questions[this.questionIndex].number
        ) {
          return 'primary'
        }

        // if marked or answered
        if (!nav.isMarked) {
          if (!nav.isAnswered) {
            return 'outline-primary'
          }
          return 'secondary'
        }
        return 'warning'
      }
      return 'outline-primary'
    },
    countAnswered() {
      let count = 0
      this.questionNavigations.forEach(nav => {
        if (nav.isAnswered) {
          count += 1
        }
      })

      return count
    },
    checkIfAnswered() {
      const quiz = this.questions[this.questionIndex]
      if (quiz.answer_text) {
        this.questionNavigations[this.questionIndex].isAnswered = true
      }
    },
    navigateToQuiz(index, paginate = false) {
      this.saveAnswer(this.answer)
      this.checkIfAnswered()
      // set answer for essay
      this.setAnswer(this.answer)

      // navigate to quiz
      if (!paginate) {
        this.questionIndex = index
      } else {
        this.questionIndex += index
      }

      // load quiz answer to data
      this.answer = this.questions[this.questionIndex].answer_text

      // set last viewed quiz
      this.setLastViewedQuestion()

      // saving quiz session
    },
    setLastViewedQuestion() {
      if (this.questionIndex >= this.quizLastViewed) {
        this.quizLastViewed = this.questionIndex
      }
    },
    markQuiz() {
      this.questionNavigations[this.questionIndex].isMarked = !this.questionNavigations[
        this.questionIndex
      ].isMarked
    },

    setAnswer(answer) {
      this.checkIfAnswered()
      this.answer = answer
      this.countIsAnswered = this.countAnswered()
    },

    // Quiz Submit Form
    submitForm() {
      // show confirmation dialog
      this.saveAnswer(this.answer)
      this.$swal({
        title: 'Akhiri Tugas?',
        text: 'Anda tidak akan dapat mengubah jawaban setelah mengakhiri tugas',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Simpan',
        cancelButtonText: 'Batal',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.finishTask()
        }
      })
    },
    async finishTask() {
      await reqFinsihTaskEssay(this.$route.params.taskId).then(response => {
        this.showToast(
          'success',
          'CheckIcon',
          'Berhasil',
          'Tugas berhasil dikumpulkan',
        )

        this.$router.push({
          name: checkAuthorizeRole('chapter-tasks.show'),
          params: {
            trainingId: this.$route.params.trainingId,
            chapterId: this.$route.params.chapterId,
          },
        })
      }).catch(error => {
        this.showToast(
          'danger',
          'CheckIcon',
          'Gagal',
          error.response.data.message || 'Tugas gagal dikumpulkan',
        )
      })
    },

    // Timer
    startTimer() {
      this.timerInterval = setInterval(() => (this.timePassed += 1), 1000)
    },
    onTimesUp() {
      this.finishTask()
    },
    checkIfTimeIsUp() {
      let status = false
      if (this.timeDurationOnSecond < this.timePassed) {
        status = true
      }

      return status
    },
  },
}
</script>

<style scss>
.progress-quiz {
  width: 100%;
}

.exam-answer-options .form-control[readonly] {
  cursor: pointer;
  background-color: white !important;
}

.card-material-type {
  cursor: pointer;
}

.round-button {
  padding: 0.2%;
  margin: 0.7%;
  font-size: 0.7rem;
  height: 2.5rem;
  width: 2.5rem;
}

.small-gap-left {
  margin-left: 10px;
}
.small-gap-bottom {
  margin-bottom: 10px;
}
.small-gap-right {
  margin-right: 10px;
}
</style>
